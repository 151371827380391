<template>
  <div class="leads-container">
    <CCard class="leads-card">
      <CCardHeader class="leads-header">
        <div class="leads-title">
          <CIcon name="cil-people" />
          <h4>Lead-Übersicht</h4>
        </div>
        <div class="leads-summary">
          <div class="summary-item">
            <strong>{{ totalLeads }}</strong> Gesamt
          </div>
          <div class="summary-item">
            <strong>{{ newLeadsToday }}</strong> Heute neu
          </div>
          <div class="summary-item">
            <strong>{{ formatCurrency(averageLeadValue) }}</strong> Ø Wert
          </div>
        </div>
        <div class="leads-actions">
          <CButton 
            v-if="$commons.isOrganizationUser()" 
            :color="organizationWide ? 'primary' : 'secondary'"
            @click="toggleOrganizationWide"
          >
            {{ organizationWide ? 'Organisations-weit' : 'Instanz-weit' }}
          </CButton>
          <CButton color="success" @click="exportCSV">
            <CIcon name="cil-cloud-download" /> Export (CSV)
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <div class="leads-filters">
          <div class="filter-group">
            <label for="quickSearch">Suche in aktueller Ansicht</label>
            <div class="search-container">
              <CInput
                id="quickSearch"
                v-model="quickSearchQuery"
                placeholder="Schnellsuche..."
                class="search-input"
                @input="onQuickSearchInput"
              >
                <template #prepend-content><CIcon name="cil-search" /></template>
              </CInput>
              <CButton color="primary" @click="toggleAdvancedSearch">
                {{ showAdvancedSearch ? 'Einfache Suche' : 'Erweiterte Suche' }}
              </CButton>
            </div>
          </div>
          
          <div v-if="showAdvancedSearch" class="filter-group">
            <label for="advancedSearch">Erweiterte Suche in allen Leads</label>
            <div class="advanced-search">
              <CInput
                id="advancedSearch"
                v-model="advancedSearchQuery"
                placeholder="Erweiterte Suche über alle Daten..."
                class="search-input"
              >
                <template #prepend-content><CIcon name="cil-search" /></template>
              </CInput>
              <CButton color="success" @click="performAdvancedSearch">
                Suche starten
              </CButton>
            </div>
          </div>
          
          <div class="filter-group">
            <label for="typeSelect">Typ</label>
            <CSelect
              id="typeSelect"
              :value="selectedType"
              :options="typeOptions"
              placeholder="Typ filtern"
              @update:value="onTypeChange"
            />
          </div>

          
          <div class="filter-group">
            <label for="datePicker">Datum</label>
            <Datepicker
              id="datePicker"
              v-model="selectedDate"
              placeholder="Datum auswählen"
              @change="onDateChange"
            />
          </div>
          
          <div class="filter-group">
            <label for="itemsPerPage">Einträge pro Seite</label>
            <CSelect
              id="itemsPerPage"
              v-model="itemsPerPage"
              :options="itemsPerPageOptions"
              class="items-per-page-select"
            />
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-striped" :key="tableData.length">
            <thead>
              <tr>
                <th v-for="field in tableFields" :key="field.key">
                  {{ field.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in paginatedData" :key="item.id">
                <td>
                  <CBadge :color="getTableBadge(item.type)">
                    {{ translateType(item.type) }}
                  </CBadge>
                </td>
                <td>
                  <div>{{ item.holder.firstName }}</div>
                  <div>{{ item.holder.lastName }}</div>
                </td>
                <td>{{ item.holder.phoneNumber }}</td>
                <td>{{ item.holder.email }}</td>
                <td>{{ item.values?.address?.formattedAddress || 'Nicht angegeben' }}</td>
                <td>{{ formatCurrency(item.results?.resultAbsolute) }}</td>
                <td>{{ formatDate(item.created) }}</td>
                <td>
                  <div class="lead-actions">
                    <CButton
                      :to="'leads/edit/' + item.id"
                      color="primary"
                      variant="ghost"
                      size="sm"
                    >
                      Details
                    </CButton>
                    <CButton
                      @click="deleteLead(item)"
                      color="danger"
                      variant="ghost"
                      size="sm"
                    >
                      <CIcon name="cil-trash" />
                    </CButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-container">
          <CPagination
            v-if="totalPages > 1"
            :pages="totalPages"
            :active-page="currentPage + 1"
            align="center"
            @update:activePage="pageChanged"
          />
        </div>
      </CCardBody>
    </CCard>
    <CSpinner v-if="loading" color="primary" />
  </div>
</template>



<script>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import commons from '../../commons';
import { saveAs } from 'file-saver';
import Datepicker from 'vuejs-datepicker'

export default {
  name: "Leads",
  components: {
    Datepicker,
  },
  setup() {
    const tableData = ref([]);
    const searchQuery = ref('');
    const selectedType = ref('');
    const organizationWide = ref(commons.getFromStorage('leads_organizationWide', false));
    const itemsPerPage = ref(10);
    const currentPage = ref(0);
    const sortKey = ref('created');
    const sortOrder = ref('desc');
    const totalLeads = ref(0);
    const loading = ref(false);
    const newLeadsToday = ref(0);
    const averageLeadValue = ref(0);
    const selectedDate = ref(null);
    const quickSearchQuery = ref('');
    const advancedSearchQuery = ref('');
    const showAdvancedSearch = ref(false);

  const onTypeChange = (newValue) => {
    selectedType.value = newValue;
  };




    const typeOptions = [
      { value: '', label: 'Alle Typen' },
      { value: 'APARTMENT', label: 'Wohnung' },
      { value: 'HOUSE', label: 'Haus' },
      { value: 'LAND', label: 'Grundstück' },
      { value: 'APARTMENT_HOUSE', label: 'Mehrfamilienhaus' },
      { value: 'RENT_APARTMENT', label: 'Mietwohnung' },
      { value: 'RENT_HOUSE', label: 'Miethaus' },
    ];

    const itemsPerPageOptions = [
      { value: 10, label: '10' },
      { value: 20, label: '20' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ];

    const tableFields = [
      { key: 'type', label: 'Typ' },
      { key: 'holder.fullName', label: 'Name' },
      { key: 'holder.phoneNumber', label: 'Telefon' },
      { key: 'holder.email', label: 'E-Mail' },
      { key: 'values.address.formattedAddress', label: 'Anschrift' },
      { key: 'results.resultAbsolute', label: 'Leadwert' },
      { key: 'created', label: 'Datum' },
      { key: 'actions', label: 'Aktionen' },
    ];

    const loadLeads = async () => {
      loading.value = true;
      try {
        const response = await axios.get("/lead", {
          params: {
            page: currentPage.value,
            size: itemsPerPage.value,
            organizationWide: organizationWide.value,
            sort: `${sortKey.value},${sortOrder.value}`,
            type: selectedType.value,
            date: selectedDate.value ? formatDateForAPI(selectedDate.value) : null
          }
        });
        console.log("API response:", response.data);
        if (response.data && Array.isArray(response.data.content)) {
          tableData.value = response.data.content;
          console.log("Updated tableData:", tableData.value);
          totalLeads.value = response.data.totalElements;
        } else {
          console.error("Unerwartete API-Antwortstruktur", response.data);
          tableData.value = [];
          totalLeads.value = 0;
        }
        loading.value = false;
      } catch (error) {
        console.error("Problem beim Laden der Leads", error);
        loading.value = false;
      }
    };

    const formatDateForAPI = (date) => {
      if (!date) return null;
      return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    };

    const onDateChange = () => {
      console.log("Date changed:", selectedDate.value);
      currentPage.value = 0;
      loadLeads();
    };

  const totalPages = computed(() => Math.ceil(filteredTableData.value.length / itemsPerPage.value));

   const filteredTableData = computed(() => {
    return tableData.value.filter(item => {
      const quickSearchLower = quickSearchQuery.value.toLowerCase();
      const advancedSearchLower = advancedSearchQuery.value.toLowerCase();
      
      const quickSearchMatch = 
        item.holder.firstName.toLowerCase().includes(quickSearchLower) ||
        item.holder.lastName.toLowerCase().includes(quickSearchLower) ||
        item.holder.email.toLowerCase().includes(quickSearchLower) ||
        (item.holder.phoneNumber && item.holder.phoneNumber.includes(quickSearchLower));
      
      const advancedSearchMatch = advancedSearchLower
        ? JSON.stringify(item).toLowerCase().includes(advancedSearchLower)
        : true;
      
      const typeMatch = selectedType.value ? item.type === selectedType.value : true;
      
      return quickSearchMatch && advancedSearchMatch && typeMatch;
    });
  });




    const onQuickSearchInput = () => {
      console.log("Quick search input changed:", quickSearchQuery.value);
      // Keine Serveranfrage nötig, da filteredTableData die Filterung übernimmt
    };

    const toggleAdvancedSearch = () => {
      showAdvancedSearch.value = !showAdvancedSearch.value;
    };

    const performAdvancedSearch = async () => {
    loading.value = true;
    try {
      const response = await axios.get("/lead", {
        params: {
          page: 0,
          size: 1000, // Erhöhen Sie diesen Wert, um mehr Ergebnisse zu erhalten
          organizationWide: organizationWide.value,
          sort: `${sortKey.value},${sortOrder.value}`,
          search: advancedSearchQuery.value,
          type: selectedType.value,
          date: selectedDate.value ? formatDateForAPI(selectedDate.value) : null
        }
      });
      if (response.data && Array.isArray(response.data.content)) {
        tableData.value = response.data.content;
        totalLeads.value = response.data.totalElements;
      } else {
        console.error("Unerwartete API-Antwortstruktur", response.data);
        tableData.value = [];
        totalLeads.value = 0;
      }
    } catch (error) {
      console.error("Problem bei der erweiterten Suche", error);
    } finally {
      loading.value = false;
    }
  };



    const onSearchInput = () => {
      console.log("Search input changed:", searchQuery.value);
      // No need to reload data, filteredTableData will handle the filtering
    };

    const updateSummaryData = async () => {
      try {
        const response = await axios.get("/lead/summary", {
          params: { organizationWide: organizationWide.value }
        });
        newLeadsToday.value = response.data.newLeadsToday;
        averageLeadValue.value = response.data.averageLeadValue;
      } catch (error) {
        console.error("Problem beim Laden der Zusammenfassung", error);
      }
    };

    const toggleOrganizationWide = () => {
      organizationWide.value = !organizationWide.value;
      commons.setToStorage('leads_organizationWide', organizationWide.value);
      currentPage.value = 0;
      loadLeads();
      updateSummaryData();
    };

    const exportCSV = async () => {
      try {
        const response = await axios.get("/lead/export", {
          params: { organizationWide: organizationWide.value },
          responseType: 'blob'
        });
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `leads_${new Date().toISOString()}.csv`);
      } catch (error) {
        console.error("Problem beim Exportieren der Leads", error);
        alert("Fehler beim Exportieren der Leads. Bitte versuchen Sie es später erneut.");
      }
    };

    const deleteLead = async (item) => {
      if (confirm(`Sind Sie sicher, dass Sie den Lead von ${item.holder.firstName} ${item.holder.lastName} löschen möchten?`)) {
        try {
          await axios.delete(`/lead/${item.id}`);
          alert("Lead erfolgreich gelöscht.");
          loadLeads();
          updateSummaryData();
        } catch (error) {
          console.error("Problem beim Löschen des Leads", error);
          alert("Fehler beim Löschen des Leads. Bitte versuchen Sie es später erneut.");
        }
      }
    };

    const getTableBadge = (type) => {
      const badgeColors = {
        APARTMENT: 'success',
        HOUSE: 'secondary',
        LAND: 'warning',
        APARTMENT_HOUSE: 'danger',
        RENT_APARTMENT: 'info',
        RENT_HOUSE: 'info',
      };
      return badgeColors[type] || 'primary';
    };

    const translateType = (type) => {
      const translations = {
        APARTMENT: 'Wohnung',
        HOUSE: 'Haus',
        LAND: 'Grundstück',
        APARTMENT_HOUSE: 'Mehrfamilienhaus',
        RENT_APARTMENT: 'Mietwohnung',
        RENT_HOUSE: 'Miethaus',
      };
      return translations[type] || type;
    };

    const formatCurrency = (value) => {
      if (value === undefined || value === null || isNaN(value)) {
        return '-';
      }
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
    };

    const formatDate = (dateString) => {
      if (!dateString) return '-';
      return new Date(dateString).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    const sortBy = (key) => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortKey.value = key;
        sortOrder.value = 'asc';
      }
      loadLeads();
    };

    const pageChanged = (page) => {
      currentPage.value = page - 1;
      loadLeads();
    };

    onMounted(() => {
      loadLeads();
      updateSummaryData();
    });

    watch([itemsPerPage, selectedDate], () => {
      console.log("Watch triggered. itemsPerPage:", itemsPerPage.value);
      console.log("Watch triggered. selectedDate:", selectedDate.value);
      currentPage.value = 0;
      loadLeads();
    });

    const paginatedData = computed(() => {
    const start = currentPage.value * itemsPerPage.value;
    const end = start + itemsPerPage.value;
    return filteredTableData.value.slice(start, end);
  });

    return {
      tableData,
      filteredTableData,
      searchQuery,
      selectedType,
      organizationWide,
      itemsPerPage,
      currentPage,
      sortKey,
      sortOrder,
      typeOptions,
      itemsPerPageOptions,
      tableFields,
      totalPages,
      totalLeads,
      newLeadsToday,
      averageLeadValue,
      loading,
      toggleOrganizationWide,
      exportCSV,
      deleteLead,
      getTableBadge,
      translateType,
      formatCurrency,
      formatDate,
      sortBy,
      pageChanged,
      selectedDate,
      onDateChange,
      onSearchInput,
      onTypeChange,
      performAdvancedSearch,
      quickSearchQuery,
      advancedSearchQuery,
      showAdvancedSearch,
      onQuickSearchInput,
      toggleAdvancedSearch,
      performAdvancedSearch,
      paginatedData,
    };
  }
};
</script>



<style scoped>

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filter-group label {
  font-weight: bold;
  margin-bottom: 5px;
}


.leads-container {
  padding: 20px;
}

.leads-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.leads-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.leads-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.leads-summary {
  display: flex;
  gap: 20px;
}

.summary-item {
  text-align: center;
}

.leads-actions {
  display: flex;
  gap: 10px;
}

.leads-filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.search-input {
  flex-grow: 1;
}

.lead-actions {
  display: flex;
  gap: 5px;
}

.table-responsive {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
  cursor: pointer;
}

th:hover {
  background-color: #e9ecef;
}

td div {
  white-space: nowrap;
}

td div:first-child {
  font-weight: bold;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.pagination-container {
  margin-top: 20px;
}

.items-per-page-select {
  width: 150px;
}
</style>
